
      @use 'styles/vars' as *;
      @use 'styles/breakpoints' as bp;
      @use 'styles/typography' as type;
      @use 'styles/colours' as col;
      @use 'styles/utilities' as util;
      @use 'styles/animations' as animate;
    
.auth {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: spacing(1);
  justify-content: center;
  place-self: center;

  form {
    max-width: 450px;
    width: 100%;
  }
}

.fieldset {
  display: flex;
  flex-direction: column;
  gap: spacing(2);
  width: 100%;
}

.form-actions {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: spacing(2);
  justify-content: space-between;
}

.register-btn {
  @include util.button-secondary;

  padding: padding(0.8) padding(4);
}
